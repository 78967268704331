import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            backgroundColor: '#000',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            backgroundColor: '#000',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  {/* <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div> */}
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <ul>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2024.2.9</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000028.000074402.html">【プレスリリース】福岡発Web3プレイヤー創出に向けた、Fukuoka Web3 Pitchを3/3に開催</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2023.9.29</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000027.000074402.html">【プレスリリース】Arbitrum Foundationとの提携により、Arbitrum Japanが日本市場で展開開始</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2023.6.27</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000026.000074402.html">【プレスリリース】福岡発Web3.0サービスの創出に向けた土壌をつくるWeb3.0 Town Hallを7/20に、FUKUOKA DAO CAMPを7/29-30に開催</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2023.5.23</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000025.000074402.html">【プレスリリース】日本初開催のDAO特化型イベントDAO TOKYO、大盛況の後に閉幕</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2023.3.24</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000024.000074402.html">【プレスリリース】4月13日開催のDAO TOKYO、登壇者、サポーターの発表及び最終チケットを販売開始</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2023.1.16</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000023.000074402.html">【プレスリリース】分散型インフラストラクチャーの利用に焦点を当てたWeb3特化型ハッカソン『Fracton Hackathon Vol.1』が1/29よりキックオフ</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.12.9</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000022.000074402.html">【プレスリリース】累計2万部突破！Fracton Ventures株式会社の共同創業者 鈴木 雄大、亀井 聡彦、赤澤 直樹の最新著書『Web3とDAO 誰もが主役になれる「新しい経済」』（かんき出版）が第5版増刷決定！</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.12.7</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000021.000074402.html">【プレスリリース】最前線のDAO特化型カンファレンス『DAO TOKYO』が2023年4月13日、神田明神にて日本初開催</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.9.26</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000020.000074402.html">【プレスリリース】Fracton VenturesとProtocol Lab、日本におけるWeb3エコシステム拡大のため戦略的パートナーシップを発表</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.7.6</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000019.000074402.html">【プレスリリース】​発売前から予約殺到、重版決定！『Web3とDAO 誰もが主役になれる「新しい経済」』7月6日より発売開始</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.5.23</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000018.000074402.html">【プレスリリース】日本初のWeb3特化型インキュベーション『Fracton Incubation 2022 powered by bitbank』スポンサー、メンター、ゲストスピーカーが決定</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.4.28</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000017.000074402.html">【プレスリリース】​日本初のWeb3特化型インキュベーション『Fracton Incubation 2022』第二期プログラムへの募集を開始</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.2.1</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000015.000074402.html">【プレスリリース】​メタバース内の文化都市開発を行う合弁会社「MetaTokyo株式会社」を設立</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2022.1.21</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000003.000090721.html">【ニュース】MUSIC3から日本初の音楽DAOへ。新時代のグローバル音楽視聴エコシステムになり得る音楽コミュニティを構築すべく「FRIENDSHIP.DAO」始動！</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.12.27</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000120.000017258.html">【ニュース】メタバース上のグローバル文化都市「メタトーキョー」がTOKYOカルチャーを代表する雑誌「FRUiTS」とのコラボ企画を開始</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.12.24</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000087.000024344.html">【ニュース】国内初Web3インキュベーションプログラムで Demo Day が開催</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.11.30</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000014.000074402.html">【プレスリリース】Fracton Ventures、日本初NEAR Foundationから2つのGrantを獲得</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.11.19</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000110.000017258.html">【プレスリリース】メタバース上のグローバル文化都市「メタトーキョー」のデジタル・パスポート「MetaTokyo Pass」が約5時間で完売！</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.11.18</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000109.000017258.html">【プレスリリース】メタバース上のグローバル文化都市「メタトーキョー」が第一弾企画を開始。日本最大級のNFTアートプロジェクト「Generativemasks」他とのコラボミュージアムを世界へ展開</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.9.15</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000013.000074402.html">【プレスリリース】日本初のWeb3特化型インキュベーションプログラムFracton Incubation 2021 Powered by bitbankに全9プロジェクトが採択</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.9.1</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000002173.000003670.html">【出演情報】10月9日（土）・10日（日）に開催される日本最大級のデジタル・クリエイティブフェスティバル「J-WAVE INNOVATION WORLD FESTA 2021 supported by CHINTAI」（イノフェス）の2日目にイノベーターとして出演することが決定</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.8.3</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000080.000017258.html">【プレスリリース】NFTを活用した次世代メタバース上に、グローバル文化都市「メタトーキョー」を創り出すWeb3プロジェクトが始動</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.7.28</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000012.000074402.html">Fracton Ventures、日本初のWeb3特化型インキュベーションプログラムを開催、本日よりプロジェクトの募集を開始</a>
                      </li>                     
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.7.16</p>
                        <a href="https://www.neweconomy.jp/posts/135576">【記事掲載：あたらしい経済】【速報】北島康介、ブロックチェーン活用の競泳プロチーム運営に意欲。「Tokyo Frog Kings」がフラクトン、パレードオールと提携</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.7.16</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000011.000074402.html">【プレスリリース】競泳金メダリスト北島康介氏が率いるTokyo Frog Kingsは、Fracton Ventures、ParadeAllと提携し、プロスポーツチームのWeb3.0化を進める共同プロジェクトを開始</a>
                      </li>
                    <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.6.21</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000010.000074402.html">【プレスリリース】人気ロックバンド UVERworld のボーカリスト TAKUYA∞のNFT フォトアート作品の企画、開発支援を行いました</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.6.11</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000008.000074402.html">【プレスリリース】Fracton VenturesとMintGateが提携、トークンゲーティングを活用した事例創出及び、共同でクリエイターを支援するアフィリエイトプログラムの開発に取り組むことを発表</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.5.14</p>
                        <a href="https://crypto-times.jp/fracton-ventures-near/">【記事掲載：Crypto Times】Fracton Ventures、NEARプロトコルがインドにて行うアクセラレーションプログラム「NEAR India Accelerator」にパートナー企業として参画</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.5.14</p>
                        <a href="https://www.neweconomy.jp/posts/116842">【記事掲載：あたらしい経済】フラクトン、インドでのNEARプロトコルのアクセラレーションプログラムにパートナー企業として参加</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.5.14</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000006.000074402.html">【プレスリリース】Fracton Ventures、NEARプロトコルがインドにて行うアクセラレーションプログラム「NEAR India Accelerator」にパートナー企業として参画</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.5.11</p>
                        <a href="https://www.neweconomy.jp/features/tokenen/">【連載企画：あたらしい経済】当社共同創業者/CTO 赤澤による連載企画「トークンエンジニアリングという武器」の掲載を本日よりスタート</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.5.11</p>
                        <a href="https://www.neweconomy.jp/posts/115709">【記事掲載：あたらしい経済】【速報】Web3.0をテーマにした共同イベントやコンテンツ発信へ、幻冬舎あたらしい経済とFracton Venturesが提携</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.5.11</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000005.000074402.html">【プレスリリース】Fracton Venturesと幻冬舎あたらしい経済が提携し、Web3.0をテーマにした共同イベントやコンテンツ発信を開始</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.4.30</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000039.000036433.html">【プレスリリース】FXcoin、Fracton Ventures株式会社と顧問契約締結</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.4.21</p>
                        <a href="https://news.yahoo.co.jp/articles/4983fdf348c06f1f54fab78c5b476940e11a8311">【記事掲載：Yahoo!ニュース】マーケティングや人事分野でデジタルデータの活用を検討　GiRAFFE&Co.がブロックチェーンの技術で</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.4.14</p>
                        <a href="https://kyodonewsprwire.jp/release/202104133648">【プレスリリース】デジタルマーケティングのGiRAFFE＆Co.、デジタルマーケティング分野とHR分野におけるNFTの活用の検討開始</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.4.9</p>
                        <a href="https://signal.diamond.jp/articles/-/672">【記事掲載：DIAMOND SIGNAL】NFTやDeFiなどで注目──新しいインターネットの世界「Web 3.0」とは一体何か？</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.4.4</p>
                        <a href="https://decrypt.co/63807/we-can-dao-it-how-a-new-wave-of-cypherpunks-is-disrupting-venture-capital">【記事掲載：Decrypt】'We Can DAO It': How a New Wave of CypherPunks Is Disrupting Venture Capital</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.4.2</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000004.000074402.html">【プレスリリース】Fracton Ventures株式会社、創・佐藤法律事務所とトークンの新規発行に関するコンサルティングに関するワンストップでの助言を行うため、業務提携を締結</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.4.2</p>
                        <a href="https://news.mynavi.jp/article/conspicuous-22/">【記事掲載：マイナビニュース】知らないとマズイ!? 『Web3.0』と『DAO』が起こすパラダイムシフト</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.3.29</p>
                        <a href="https://hedge.guide/feature/what-is-nft-bc202103.html">【記事掲載：HEDGE GUIDE】【特集コラム】値段が高いだけがNFTじゃない！NFTの面白さ教えます（基礎編）</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.3.16</p>
                        <a href="https://www.neweconomy.jp/posts/97387">【記事掲載：あたらしい経済】音楽業界のNFT事業支援を目指す、Fracton VenturesとParadeAllがパートナーシップ契約</a>
                      </li>
                      <li style={{listStyle: 'none', marginBottom:'20px'}}>
                        <p style={{marginBottom:'0px'}}> 2021.3.16</p>
                        <a href="https://prtimes.jp/main/html/rd/p/000000003.000074402.html">【プレスリリース】ParadeAll株式会社とNFT活用をはじめとする、音楽アーティストへの新規事業領域での戦略的パートナーシップ契約を締結</a>
                      </li>
                    
                    </ul>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="columns" style={{marginBottom: '10rem'}}>
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/jp/team">
                      See more Fracton Ventures
                    </Link>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest Web3 Magazine
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered" style={{marginTop: '5rem'}}>
                    <Link className="btn" to="/jp/magazine">
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
